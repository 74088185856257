<template>
  <div class="d-flex align-center" :class="`background-auth${$vuetify.theme.dark ? '-dark' : ''}`" style="height: 100vh;">
    <v-card class="mx-auto text-left pa-8 bgPanel" :width="480" flat align="center" justify="center">
      <v-col class="text-center pa-0 mt-2 mb-6">
        <img :src="require(`@/assets/appBrand--ax-full${ $vuetify.theme.dark ? '-dark' : ''}.svg`)" width="211">
        <span class="mt-2 d-block subtitle-1 grey-700--text font-weight-bold">Iniciar sesión</span>
      </v-col>
      <span class="text-body-2 text-grey-300--text">Email</span>
      <v-text-field v-model="$v.user.email.$model" @keyup.enter="signIn" :error="$v.user.email.$error" outlined hide-details required single-line dense />
      <span class="d-block mt-5 text-body-2 text-grey-300--text">Contraseña</span>
      <v-text-field v-model="$v.user.password.$model" @keyup.enter="signIn" :error="$v.user.password.$error" outlined hide-details required single-line class="pa-0" dense type="password">
        <template v-slot:append>
          <v-btn class="mt-n1 mr-4 font-weight-medium" :to="{name: 'AuthPasswordReset'}" text color="blue-500" small :ripple="false">¿La olvidaste?</v-btn>
        </template>
      </v-text-field>
      <v-col v-if="error" class="pa-0 mt-3 text-center">
        <v-alert border="left" colored-border color="white" dense class="body-2 red--text pa-0">{{error}}</v-alert>
      </v-col>
      <v-btn class="mt-13 mb-2" @click="signIn" :loading="signInLoader" color="blue-500" block>Iniciar sesión</v-btn>
    </v-card>
  </div>
</template>
<script>
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  data: () => ({
    user: {
      email: '',
      password: ''
    },
    error: '',
    signInLoader: false
  }),
  created () {
    if (this.$store.state.auth.user.authenticated) {
      this.$router.push({
        name: 'AccountDashboard'
      })
    }
  },
  methods: {
    signInGoogle () {
      this.$gAuth.getAuthCode()
      .then(code => {
        const payload = {
          provider: 'google-oauth2',
          code: code
        }
        this.$store.dispatch('auth/GET_AUTH', {
          resource: '/auth/google/',
          payload: payload
        })
      })
      .then(response => {
        // after ajax
      })
    },
    signIn () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return false
      }
      const payload = {
        username: this.user.email,
        password: this.user.password
      }
      this.signInLoader = true
      this.$store.dispatch('auth/GET_AUTH', {
        resource: '/token/',
        payload: payload
      })
      .catch((error) => {
        this.error = error.response.data.detail
      })
      .finally(() => {
        this.signInLoader = false
      })
    }
  },
  validations: {
    user: {
      email: {
        required,
        email,
        maxLength: maxLength(254)
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(64)
      }
    }
  }
}
</script>